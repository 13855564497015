import * as fetch from './fetch';

interface IIWelfareActivityPageRequest { }
interface IWelfareActivityPageResponse { }

interface IIWelfareActivityGoodsDetailRequest { }
interface IWelfareActivityGoodsDetailResponse { }
/**
 *
 *  查询活动列表 分页
 *
 */

async function queryWelfareActivityPage(request: IIWelfareActivityPageRequest): Promise<IWelfareActivityPageResponse> {
  let result = await fetch.post<IWelfareActivityPageResponse>(
    '/welfareactivitiWeb/page',

    {
      ...request,
    },
  );
  return result;
}

async function queryWelfareActivityGoodsDetail(
  request: IIWelfareActivityGoodsDetailRequest,
): Promise<IWelfareActivityGoodsDetailResponse> {
  let result = await fetch.post<IWelfareActivityGoodsDetailResponse>(
    '/welfareactivitiWeb/goodsDetail',

    {
      ...request,
    },
  );
  return result;
}

/**
 *
 * 获取活动商品数量
 *
 */
async function countGoods(activitiId: string): Promise<unknown> {
  let result = await fetch.get<unknown>(
    `/site/countActivitiGoods/${activitiId}`,

    {},
  );
  return result.context;
}

export default {
  queryWelfareActivityPage,
  queryWelfareActivityGoodsDetail,
  countGoods
};
