export const formatRichText = (html) => {
  if (html) {
    let newContent = html.replace(/<td[^>]*>/gi, function (match, capture) {
      match =
        match.indexOf('border') > -1
          ? match.replace(/style="[^"]+"/gi, 'style="border:1px solid;word-break: break-all;"')
          : match;
      return match;
    });
    newContent = newContent.replace(/<img[^>]*>/gi, function (match, capture) {
      match = match.replace(/style="[^"]+"/gi, '').replace(/style='[^']+'/gi, '');
      match = match.replace(/width="[^"]+"/gi, '').replace(/width='[^']+'/gi, '');
      match = match.replace(/height="[^"]+"/gi, '').replace(/height='[^']+'/gi, '');
      return match;
    });
    newContent = newContent.replace(/style="[^"]+"/gi, function (match, capture) {
      match = match
        .replace(/width:[^;]+;/gi, 'max-width:100vw;overflow-x:hidden')
        .replace(/width:[^;]+;/gi, 'max-width:100%;');
      return match;
    });
    newContent = newContent.replace(/<br[^>]*\/>/gi, '');
    /* eslint-disable */
    newContent = newContent.replace(
      /\<img/gi,
      '<img style="width:100vw;max-width:100vw;height:auto;display:block;margin-top:0;margin-bottom:0;overflow-x:hidden"',
    );
    return newContent;
  } else return '';
};
