import * as _ from './util';
import Fetch from './fetch';
import noop from './noop';
import { storage } from './storage';
import { fetchModal, setModalShow, handleUrl } from './adv-modal';
import OrderWrapper from './order-wrapper';
import ValidConst from './validate';
import { setShopCartNum, getShopCartNum, getActivityShopCartNum } from './shop-cart-num';
import UploadImage from './upload';
import FormRegexUtil from './form-regex';
import { msg } from './msg';
import * as WMkit from './kit';
import * as VAS from './vas';
import * as wxAuth from './wx-auth';
import * as pvUvStatics from './pv_uv_statics';
import { wxShare } from './wx-share';
import { hijackRenderHoc } from './hijack-component';
const immutable = require('./immutable.min');
import fetchMock from './fetch-mock';
import { formatRichText } from './formatRichText';

export {
  _,
  storage,
  noop,
  Fetch,
  OrderWrapper,
  ValidConst,
  setShopCartNum,
  getShopCartNum,
  getActivityShopCartNum,
  UploadImage,
  msg,
  FormRegexUtil,
  WMkit,
  VAS,
  wxAuth,
  pvUvStatics,
  fetchModal,
  setModalShow,
  handleUrl,
  wxShare,
  hijackRenderHoc,
  immutable,
  fetchMock,
  formatRichText,
};
