/**
 * @desc
 *
 * @使用场景
 *
 * @company qianmi.com
 * @Date    2019/6/25
 **/

export interface IConfig {
  env: string;
  host: string;
  // 魔方配置
  magicHost: string;
  magicPage: string;
  renderHost: string;
  ossHost: string;
  pvUvHost: string;
  wechatDsn: string;
  mobileDsn: string;
  hostEnv: string;
  livePlayerId: string;
}
// @ts-ignore
const config: IConfig = __Config__;

const globalData = {};
export function setGlobalData(key, val) {
  globalData[key] = val;
}
export function getGlobalData(key) {
  return globalData[key];
}

export default config;
